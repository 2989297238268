import React, {Component} from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import { Typography } from "design-system";
import { Button } from "design-system/dist/components/form/button/Button";

export default class AnonymousBat extends Component {

  state = {
    loading: false,
    disabled: false,
    redirecting: true,
  }

  componentDidMount(){
    this.redirectToNewPage();
  }

  redirectToNewPage = () => {
    this.setState({disabled: true, loading: true});
     window.location.href = `https://${window.location.host}/login/chat/anonymous`;
     setTimeout(() => {
      this.setState({disabled: false, loading: false, redirecting: false})
     }, [2000])
  }

  render() {
      return (
        <Layout mode={true} sticky_footer={false} no_header_margin>
            {
              this.state.redirecting ? null
              :
          <div className="no-user">
              <div className="is-flex flex-column align-items-center justify-content-center is-fullheight gap-8">
              <Typography type="heading-1 ">
                This page is moved!
              </Typography>
              <Button size="large" type="tertiary-blue" label="Click Here" onClick={this.redirectToNewPage} disabled={this.state.disabled} loading={this.state.loading} />
              </div>
          </div>
            }
      </Layout>
      );
  }
}